import React, { useEffect } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import OrderCard from "./OrderCard";
import { Circles } from "react-loader-spinner";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  addOrder,
  fetchOrders,
} from "../../store/slices/order_slice";
import Pusher from "pusher-js";
import { useSnackbar } from "notistack";
import useSound from "use-sound";
import coinDropSound from '../../assets/coinDrop.mp3'
import bell from '../../assets/bell-98033.mp3'

const Orders = () => {
  const { isLoading, orders, error } = useSelector((state) => state.orders);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [play] = useSound(bell)
  const [playbell] = useSound(bell)

  function playSound() {
    return new Promise((resolve, reject) => {
      try {
        play();
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  function playSoundBell() {
    return new Promise((resolve, reject) => {
      try {
        playbell();
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
  useEffect(() => {
    dispatch(fetchOrders());
    const pusher = new Pusher("4881e840d860b6190b6a", {
      cluster: "mt1",
    });

    const channel = pusher.subscribe("notifications");

    channel.bind("new-notification", async (data) => {
      if (data.notification.type === "add-order") {
        dispatch(addOrder([{...data.order , newOrder : true}]));
       
        
        // Now you can await it
        // await playSound();
        await playSoundBell()
        
        enqueueSnackbar("new order added to list", {
          variant: "success",
        });
      }
    });

    return () => {
      channel.unbind();
      pusher.disconnect();
    };
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "calc(100vh - 80px)",
        }}
      >
        <Circles
          height="80"
          width="80"
          color={theme.palette.primary.main}
          visible={true}
        />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "calc(100vh - 80px)",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          Unknown Error With Message : {error}
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            dispatch(fetchOrders());
          }}
        >
          Retry
        </Button>
      </Box>
    );
  }
  return (
    <Box>
      {orders.length === 0 && (
        <Box
          sx={{
            position: "absolute",
            flexDirection: "column",
            left: "50%",
            top: "50%",
            transform: "translate(-50% , -50%) ",
          }}
        >
          no orders until now to display
        </Box>
      )}
      <Box>
        {orders.map((order) => (
          <OrderCard order={order} />
        ))}
<button onClick={playSound()} style={{opacity:'0'}}>playSound</button>
      </Box>
    </Box>
  );
};

export default Orders;
