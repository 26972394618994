import { Avatar, Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";

const OrderItem = ({ orderItem }) => {
  console.log(orderItem);
  return (
    <Box
      sx={{
        boxShadow: "none",
        p : 1,
        mb : 1
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Avatar
          alt="Profile Image"
          src={`${orderItem.meal.image}`}
          className="profile-image"
          sx={{ width: 60, height: 60 }}
        />
        <Box>
          <Typography variant="h5" component="h2">
            {orderItem.meal.name.en}
          </Typography>
          <Typography>{orderItem.meal.description.en}</Typography>
          <Typography variant="body2" component="p">
            {`Quantity : ${orderItem?.quantity}`}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              flexWrap: "wrap",
              my: 1,
            }}
          >
            {orderItem?.optionalIngredients?.length === 0 ? (
              <Typography
                fontSize={"12px"}
                component={"em"}
                display={"block"}
                mb={1}
              >
                no extra engredients
              </Typography>
            ) : (
              <Typography component={"em"} display={"block"}>
                Extra Engredient Required :
              </Typography>
            )}
            {orderItem?.optionalIngredients?.map((optional) => (
              <Box
                sx={{
                  px: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  backgroundColor: "grey.200",
                  borderRadius: "8px",
                }}
              >
                <Typography>{optional.name.en}</Typography>
              </Box>
            ))}
          </Box>
      {/**    <Typography variant="body2" component="p">
            {`Total : ${orderItem.total}`}
          </Typography>
        */} 
        </Box>
      </Box>
    </Box>
  );
};

export default OrderItem;
