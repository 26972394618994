import {
  Alert,
  alpha,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import OrderItem from "./OrderItem";
import Slide from "@mui/material/Slide";
import { useMutation } from "@tanstack/react-query";
import { request } from "../../api/request";
import { Circles } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { deleteOrder } from "../../store/slices/order_slice";
import { NewReleases } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderCard = ({ order }) => {
  const [open, setOpen] = React.useState(false);
  const [alterOpen, setAlterOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [messageType, setMessageType] = React.useState("error");
  const dispatch = useDispatch();
  const [statusButtonClicked, setStatusButtonClicked] = useState(null);
  const theme = useTheme();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handelAlterClose = () => {
    setAlterOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const toRunnerHandler = () => {
    handleClickOpen();
  };

  const acceptOrder = (id) => {
    return request({
      url: `/acceptOrder/${id}`,
      method: "patch",
      data: {
        status: statusButtonClicked,
      },
    });
  };

  const toRunnerMutation = useMutation({
    mutationKey: [`accept-order-${order.id}`],
    mutationFn: acceptOrder,
    onSuccess: (data) => {
      setMessage("Order deleted successfully");
      setMessageType("success");
      setAlterOpen(true);
      setOpen(false);
      dispatch(deleteOrder(order.id));
    },
    onError: (error) => {
      if (error.response) {
        switch (error.response.status) {
          case 401: {
            setMessage("You are not authorized to perform this operation");
            setMessageType("error");
            setAlterOpen(true);
            break;
          }
          case 422: {
            setMessage("Wrong data provided to the server");
            setMessageType("error");
            setAlterOpen(true);
            break;
          }
          case 500: {
            setMessage("Server issue, please try again later");
            setMessageType("error");
            setAlterOpen(true);
            break;
          }
          case 404: {
            setMessage("Destination not found");
            setMessageType("error");
            setAlterOpen(true);
            break;
          }
          default: {
            setMessage(
              "Unknown error occurred: Request failed with status code " +
                error.response.status
            );
            setMessageType("error");
            setAlterOpen(true);
            break;
          }
        }
      } else if (error.request) {
        setMessage(
          "Server responded with nothing. Check your internet connection or contact support if the problem persists."
        );
        setMessageType("error");
        setAlterOpen(true);
      } else {
        setMessage("Unknown error: " + error.message);
        setMessageType("error");
        setAlterOpen(true);
      }
    },
  });

  const handelConfirm = () => {
    toRunnerMutation.mutate(order.id);
  };

  if (toRunnerMutation.isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          backgroundColor: "white",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
          minHeight: "100vh",
          zIndex: "10",
        }}
      >
        <Circles
          height="80"
          width="80"
          color={theme.palette.primary.main}
          ariaLabel="circles-loading"
          visible={true}
        />
      </Box>
    );
  }
  return (
    <>
      <Box
        sx={{
          boxShadow: "1px 1px 10px -5px #0000005c",
          borderRadius: "8px",
          mt: 10,
          position: "relative",
          ...(order.newOrder && { border: `1px solid ${theme.palette.primary.dark}` }),
        }}
      >
        {order.newOrder && (
          <>
            <NewReleases
              color="green"
              sx={{
                position: "absolute",
                top: "-72px",
                left: "50%",
                transform: "translateX(-50%)",
                fontSize: "36px",
                animation: "newOrderAnimation 0.5s ease-in-out",
              }}
            />
            <Box
              sx={{
                height: "40px",
                width: "2px",
                backgroundColor: "primary.main",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                top: "-40px",
              }}
            />
          </>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            padding: "8px",
            position: "relative",
            overflow: "hidden",
            backgroundColor: alpha(theme.palette.primary.main, 0.08),
          }}
        >
          <Box>
            <Typography fontWeight={"600"}>{`Order ID: ${order.id}`}</Typography>
            <Typography fontWeight={"600"}>{`Floor: ${order?.table?.floor_name.en}`}</Typography>
            <Typography fontWeight={"600"}>{`Area Number: ${order?.table?.table_number}`}</Typography>
          </Box>
          <Box>
            <Button
              color="success"
              variant="outlined"
              onClick={() => {
                toRunnerHandler();
                setStatusButtonClicked(3);
              }}
              sx={{
                mr: 1,
              }}
            >
              Done
            </Button>
            <Button
              color="error"
              variant="outlined"
              onClick={() => {
                toRunnerHandler();
                setStatusButtonClicked(2);
              }}
            >
              Reject
            </Button>
          </Box>
        </Box>
        <Box>
          {order.order_items.map((orderItem) => (
            <OrderItem key={orderItem.id} orderItem={orderItem} />
          ))}
        </Box>
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
      >
        <DialogTitle>
          {statusButtonClicked === 2 ? "Reject Order" : "Mark Order As Done"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm your action by clicking on the agree button or cancel it by clicking away from the dialog or clicking on Disagree.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button
            color={statusButtonClicked === 2 ? "error" : "primary"}
            variant="contained"
            onClick={handelConfirm}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alterOpen}
        autoHideDuration={4000}
        onClose={handelAlterClose}
      >
        <Alert
          onClose={handelAlterClose}
          severity={messageType}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <style>
        {`
          @keyframes newOrderAnimation {
            0% {
              transform: translateX(-50%) scale(1);
              color: ${theme.palette.primary.main};
            }
            50% {
              transform: translateX(-50%) scale(1.3);
              color: green;
            }
            100% {
              transform: translateX(-50%) scale(1);
              color: green;
            }
          }
        `}
      </style>
    </>
  );
};

export default OrderCard;
