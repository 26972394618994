import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import { logout, useJawadAuthController } from '../context'
import logo from '../assets/logo192.png'
const Header = () => {
    const navigate = useNavigate()
    const [, dispatch] = useJawadAuthController()
    const logUserOut = () => {
        logout(dispatch , null)
        navigate('/')
    }
  return (
    <Box
        sx={{
            display : 'flex',
            alignItems : 'center',
            justifyContent : 'space-between',
            height : '80px',
            marginBottom : '15px'
        }}
    >
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
    {/* Replace with your logo */}
    <img
        src={logo} // Replace with your logo path
        alt="logo"
        style={{ height: '40px', width: '40px', objectFit: 'contain' }}
    />
    <Typography
        sx={{
            color: 'primary.main',
            textTransform: 'capitalize',
            fontSize: '22px',
        }}
    >
        Artista kitchen
    </Typography>
</Box>
        <Box>
            <Button
                variant='contained'
                color='error'
                onClick={logUserOut}
            >
                Logout
            </Button>
        </Box>
    </Box>
  )
}

export default Header